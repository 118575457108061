.my-account {
  @include desktopExperience {
    display: flex;

    aside {
      align-self: flex-start;
    }

    .main-content {
      flex-grow: 1;
    }
  }

  p.title {
    margin: 0 0 8px 0;
    color: $heading-color;
    font: $heading-font;
  }

  aside {
    border: $divider;
    border-radius: var(--border-radius);
    width: 30%;
    padding: 16px;
    margin-right: 32px;

    h3 {
      margin-top: 0;
    }

    .nav-section {
      padding: 20px 0;

      &:not(:last-child) {
        @include dashedBottomBorder;
      }

      &:last-child {
        padding-bottom: 0;
      }

      h5 {
        font-size: 16px;
        margin-bottom: 12px;
        margin-top: 0;
      }

      svg {
        width: 21px;
        height: auto;
        stroke: currentColor;
        position: relative;
        top: 4px;
        margin-right: 8px;

        &.logout-icon {
          fill: currentColor;
          width: 19px;
          margin-right: 10px;
        }
      }

      a {
        &.active {
          color: $body-color;
        }
      }
    }
  }

  hr {
    @include dashedBottomBorder;
  }
}

.membership-details {
  section {
    border: $divider;
    border-radius: var(--border-radius);
    padding: 16px;
    margin: 8px 0 24px 0;

    .status {
      display: flex;
      margin-bottom: 8px;

      .saved-card {
        border-left: $divider;
        margin-left: 14px;
        padding-left: 14px;
      }

      svg {
        width: 16px;
        height: 16px;
        padding: 3px;
        border-radius: 100%;
        position: relative;
        top: 3px;
        margin-right: 6px;
      }

      &.enrolled {
        svg {
          background-color: $success-color;
        }
      }

      &.cancelled,
      &.not-enrolled {
        svg {
          background-color: var(--medium-gray);
        }
      }
    }

    .card-icon {
      width: 23px;
      float: left;
      position: relative;
      top: 4px;
      margin-right: 10px;
    }

    h3 {
      margin-top: 0;
      margin-bottom: 16px;
    }

    .membership-field {
      margin-bottom: 12px;
    }

    .links {
      margin-top: 12px;

      a {
        display: inline-block;
        margin-right: 24px;
      }
    }
  }
}

.annotation-legend {
  line-height: 1;
  display: flex;
  flex-wrap: wrap;
  row-gap: 8px;
  margin-top: 12px;
  margin-bottom: 12px;

  .annotation-item {
    display: flex;
    font-size: 14px;
    color: $calendar-legend-text-color;
    align-items: center;
    margin-right: 16px;
    gap: 0.5em;

    .badge {
      flex-shrink: 0;
    }

    .price-increase {
      width: 13px;
      height: 13px;
      font-size: 10px;
      text-align: center;
      padding-top: 2px;
      color: white;
      border-radius: 50%;
      background-color: $primary-color;
    }

    .sold-out {
      width: 10px;
      height: 1px;
      background-color: $calendar-disabled-day-text-color;
      border-radius: 0;
      padding: 0;
    }
  }
}


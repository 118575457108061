@import "@/styles/globals.scss";
// Fallback theme
@import '../../themes/fallback/main';

.event-listing article.event .venue,
article.event .venue {
  color: #ba9765;
}

.event-route article.event .content-wrapper {
  display: flex;
  flex-direction: column-reverse;
}

.event-listing .event-listing-item {
  @include large {
    .venue {
      grid-row: 1;
    }
  }

  @include smallAndMedium {
    figure {
      grid-row: 1;
    }

    .venue {
      grid-row: 2;

      &:not(:first-child) {
        margin-top: 20px;
      }
    }

    .title-link {
      grid-row: 3;

      h2 {
        margin-top: 0;
      }
    }

    .subtitle {
      grid-row: 4;
    }

    .description {
      grid-row: 5;
    }

    .button.primary {
      grid-row: 6;
    }
  }
}

// TODO: Merge into styles/_page.scss

body {
  &.mobile-cart-open,
  &.mobile-event-description-open {
    @include mobileExperience {
      overflow: hidden;
    }
  }
}

.body-inner {
  &.route-event,
  &.route-event-action,
  &.route-membership-redeem,
  &.route-checkout {
    @include mobileExperience {
      .site-header,
      .site-footer-wrapper {
        display: none;
      }

      .site-content-inner {
        padding: 0;
        margin: 0;
      }

      .site-content {
        margin-top: 0;
        padding-top: 0;
        padding-bottom: 30px;
      }
    }
  }
}

/**
 * !important so higher-specificity styles cannot override these
 * TODO: Switch to CSS cascade layers when browser support is sufficient
 */
@include desktopExperience {
  .mobile {
    display: none !important;
  }
}

@include mobileExperience {
  .desktop {
    display: none !important;
  }
}

// Only show content to screenreaders
.visually-hidden {
  border: 0;
  padding: 0;
  margin: 0;
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip-path: inset(50%);
  white-space: nowrap;
}

@include mobileExperience {
  .mobile-full-screen {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: $dialog-background-color;
    padding: 80px 16px 16px;
    z-index: 11;
    transition: transform 0.2s ease-out;
  }

  .desktop {
    display: none !important;
  }
}

.forgot-membership-route,
.reset-password-route {
  form,
  .navigate-back {
    margin-top: $spacing-m;
  }
}

.reset-password-route {
  label.form-input {
    max-width: 560px;
  }
}

header.site-header {
  .site-header-inner {
    @extend %content-container;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
  }

  .logo {
    a {
      line-height: 0;
      display: block;
      padding: 0;
      margin: 0;
    }
  }
}

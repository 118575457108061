.gom-gift-code {
  &-applied {
    display: flex;
    align-items: center;

    strong {
      font-weight: 600;
    }

    svg {
      border-radius: 100%;
      background-color: $success-color;
      width: 15px;
      height: 15px;
      padding: 3px;
      margin-left: 5px;
    }
  }

  &-wrapper {
    form {
      margin-top: 40px;
    }

    .form-input {
      display: grid;
      grid-template-columns: 1fr auto;

      @include desktopExperience() {
        max-width: 300px;
      }

      input[type='text'] {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      button {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }

      .message {
        grid-column: 1 / span 2;
      }
    }

    .message.error {
      margin-top: 32px;
    }
  }
}

.redeem-membership-form .membership-level {
  .radio-items {
    margin-top: 32px;
  }

  .radio-item {
    padding: 16px;

    &:first-child {
      outline: 2px solid $promo-color;
      background-color: $promo-background-color;
      border-radius: 4px;
      position: relative;

      input {
        border-color: $promo-color;

        &::after {
          background-color: $promo-color;
        }
      }

      .label .price-wrapper {
        color: $promo-color;
      }

      &:before {
        display: flex;
        content: 'Your Gift';
        text-transform: uppercase;
        font-size: 12px;
        color: white;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 5px 10px;
        text-align: center;
        justify-content: center;
        background: $promo-color;
        border-radius: 12px;
      }
    }

    .label {
      display: flex;
      justify-content: space-between;

      > span {
        word-break: break-word;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

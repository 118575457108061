header.site-header {
  background: $header-background;
  border-top: var(--header-top-border);
  border-bottom: var(--header-bottom-border);
  box-shadow: var(--header-drop-shadow);
  height: var(--header-height);

  @include desktopExperience {
    height: var(--header-height-desktop, var(--header-height));
  }

  .logo img {
    height: var(--logo-height);
    object-fit: contain;

    @include desktopExperience {
      height: var(--logo-height-desktop, var(--logo-height));
    }
  }
  .account-links > a {
    color: $members-links-color;

    @include mobileExperience {
      font-size: 14px;
      padding: 2px 8px;
    }

    &:hover,
    &:focus {
      color: $members-link-color-hover;
    }
  }
}

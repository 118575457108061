.schedule-payments-wrapper {
  h3 {
    margin-top: 0;
    margin-bottom: 10px;
  }

  h3 {
    @extend %h5;
  }

  .amount-summary {
    .amount-to-pay {
      display: inline-block;
      font-size: 36px;
      font-weight: 600;
      margin: 0;
    }

    .overdue-amount {
      margin-left: 0.5em;
    }
  }

  .payment-amount-options {
    border: $divider;
    margin-top: 16px;

    label {
      display: flex;
      gap: 12px;
      padding: 16px;
      border-bottom: $divider;
      border-bottom-style: dashed;

      &:last-child {
        border-bottom: 0;
      }
    }

    .other-amount-input {
      position: relative;
      display: block;
      margin-top: 16px;

      &::after {
        position: absolute;
        left: 12px;
        top: 50%;
        transform: translateY(-50%);
        content: attr(data-currency-symbol);
        font: $input-font;
      }

      // Hide stepper
      input {
        /* Chrome, Safari, Edge, Opera */
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        /* Firefox */
        &[type=number] {
          -moz-appearance: textfield;
        }

        padding-left: 22px;
      }
    }
  }

  .schedule-payments-amount {
    padding: 16px;
    border: $divider;
    border-bottom: 0;
  }

  .schedule-payments-all-payments {
    border: $divider;
    padding: 0 16px;

    &.expanded {
      .heading {
        .svg-icon {
          transform: rotateY(180deg);
        }
      }
    }

    .heading {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: $link-color;
      font-size: 16px;
      margin-bottom: 0;
      padding: 16px;
      margin: 0 -16px;
      font-weight: 400;
      cursor: pointer;

      .svg-icon {
        stroke: $link-color;
      }
    }

    .payment-lists {
      border-top: $divider;
    }
  }

  .completed-payments,
  .scheduled-payments {
    h3 {
      @extend %label;
      margin-top: 24px;
      margin-bottom: 0;
    }

    .table-wrapper {
      border: $divider;
      margin-top: 16px;
      margin-bottom: 16px;
    }

    table {
      padding-left: 0;
      width: 100%;
      border-collapse: collapse;
      border: 0;

      tr {
        &:last-child {
          td {
            border-bottom: 0;
          }
        }

        td {
          padding: 16px;
          border-bottom: $divider;
          border-bottom-style: dashed;

          &:nth-child(1) {
            width: 40%;
          }

          &:nth-child(2) {
            text-align: right;
            width: 30%;
          }

          &:nth-child(3) {
            padding-left: 60px;
            width: auto;

            @include mediumOnly {
              padding-left: 40px;
            }

            @include small {
              padding-left: 0;
            }
          }

        }
      }
    }
  }

  .price-wrapper {
    display: inline-block;
  }

  .status {
    font-size: 10px;
    text-transform: uppercase;
    padding: 6px 10px;
    border-radius: 14px;
    font-weight: 700;
    letter-spacing: 0.3px;
    color: #6c6c6c;
    background-color: rgba(0, 0, 0, 0.1);

    .dark-theme & {
      color: #757575;
      background-color: rgba(255,255,255,0.1);
    }

    &.upcoming {
      color: $info-color;
      background-color: $info-background-color;
    }

    &.paid {
      color: $success-color;
      background-color: $success-background-color;
    }

    &.overdue {
      color: $error-color;
      background-color: $error-background-color;
    }
  }
}

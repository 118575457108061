.date-picker-modal {
  .date-picker-wrapper {
    padding: 0;
  }
}

.modal:has(.date-picker-wrapper) {
  .modal-window {
    --mobile-modal-top-offset: 0px;
    max-width: 1010px;
  }

  .modal-header {
    padding: 16px 24px;
  }

  .modal-body {
    padding: 24px;
    @include mobileExperience {
      padding: 0px;
    }
  }
}

.modal:has(.date-picker-wrapper.single-month) {
  .modal-window {
    max-width: 550px;
  }
}

/**
 * Date picker style
 */
.date-picker-wrapper {
  position: relative;

  .picker-calendar-mobile-header {
    display: none;
    @include mobileExperience {
      display: table;
      width: 100%;
      margin-bottom: 0px;
      border-collapse: collapse;
      border: none;

      th {
        overflow: hidden;
        border-bottom: $calendar-cell-border;
        padding: 0px;
        margin: 0px;
        width: 14.2857%;

        abbr {
          display: block;
          width: 100%;
          padding: 12px 0px;
          text-align: center;
          text-decoration: none;
        }
      }
    }
  }

  .nav-buttons {
    @include mobileExperience {
      display: none;
    }

    .prev-month {
      top: 4px;
      left: 0px;
    }

    .next-month {
      top: 4px;
      right: 0px;
    }

    button {
      position: absolute;
      display: block;
      z-index: 100;
      width: 40px;
      height: 40px;
      border: $calendar-button-border;
      border-radius: 50%;
      text-align: center;
      padding-top: 5px;
      font-size: 12px;
      background: $calendar-button-color;
      line-height: 15px;

      &.disabled {
        display: none;
      }
      svg {
        stroke: $calendar-button-icon-color;
      }
    }
  }

  .calendar {
    margin: 0px;
    padding: 0px;
    display: grid;
    grid-auto-flow: column;
    line-height: 16.5px;

    width: 100%;
    overflow-y: hidden;
    overflow-x: hidden;
    overscroll-behavior-x: contain;
    scroll-snap-type: x mandatory;

    @include mobileExperience {
      overflow-y: auto;
      overflow-x: hidden;
      overscroll-behavior-x: hidden;
      overscroll-behavior-y: contain;
      scroll-snap-type: y mandatory;
      grid-auto-flow: row;
      height: 495px;
      margin-top: 0px;
    }

    li {
      display: inline-block;
      scroll-snap-align: start;

      @include mobileExperience {
        width: 100%;
        scroll-snap-align: center;
        margin: 0rem;
      }
    }
  }

  .picker-month {
    margin: 11px;

    @include mobileExperience {
      margin: 0px;
    }
    table {
      border-collapse: collapse;
      table-layout: fixed;

      @include mobileExperience {
        margin-bottom: 16px;
        width: 100%;
      }

      thead {
        @include mobileExperience {
          display: none;
        }

        th {
          border: none;
          font-weight: normal;
          font-size: 12px;
          text-align: center;
          height: 24px;
          overflow: hidden;
          padding: 10px 0 16px;
          margin: 0px;

          abbr {
            display: block;
            width: 100%;
            padding: 10px 0px;
            text-align: center;
            text-decoration: none;
          }
        }
      }

      th,
      td {
        overflow: hidden;
        border: $calendar-cell-border;
        padding: 0px;
        margin: 0px;

        &.day-cell-empty {
          border: 0px;
          height: 0px;
        }
      }

      caption {
        font-weight: 600;
        padding: 5px;
        font-size: 18px;
        text-align: center;
        color: $calendar-month-text-color;

        @include mobileExperience {
          text-align: left;
          padding: 16px 16px;
        }
      }
    }

    &.show-prices:not(.show-availability) {
      .picker-day {
        padding-top: 22px;
      }
    }

    &.show-availability:not(.show-prices) {
      .picker-day {
        padding-top: 25px;
      }
    }

    &.show-availability.show-prices {
      .picker-day {
        padding-top: 16px;
      }
    }

    .picker-day {
      background: none repeat scroll 0 0 transparent;
      border: medium none;
      border-spacing: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 66px;
      height: 72px;
      color: $calendar-day-text-color;
      text-align: center;
      position: relative;
      font: $body-font;

      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      padding-top: 30px;
      gap: 2px;

      @include mobileExperience {
        width: 100%;
      }

      &:hover,
      &:focus {
        background: $calendar-hover-day-background-color;
        div,
        span {
          color: $calendar-hover-day-text-color;
        }
      }

      &.focused {
        outline: 2px solid $calendar-selected-day-outline-color;
        outline-offset: -2px;

        &:hover,
        &:focus {
          background: color-mix(in srgb, $calendar-selected-day-outline-color 4%, transparent);
        }
      }

      .date {
        margin: 0px;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        color: $calendar-day-text-color;
      }

      .price,
      .price-wrapper {
        font-size: 11px;
        line-height: 12px;
        margin: 0px;
        padding: 0px;
        color: $calendar-price-text-color;
        width: 100%;
      }

      .availability-status {
        font-size: 10px;
        line-height: 11px;
        color: $calendar-availability-text-color;

        &.danger {
          color: $error-color;
        }
      }

      .badges {
        display: flex;
        gap: 3px;
        justify-content: center;

        position: absolute;
        bottom: 5px;
        height: 6px;
        line-height: 0;

        .badge {
          margin: 0px;
          padding: 0px;
          line-height: 0;
          width: 6px;
          height: 6px;
        }
      }

      &.disabled {
        cursor: not-allowed;
        background: $calendar-disabled-day-background-color;
        color: $calendar-disabled-day-text-color;
        opacity: 0.9;
        &:hover,
        &:focus {
          background: $calendar-disabled-day-background-color;
        }

        div {
          color: $calendar-disabled-day-text-color;
        }

        &.sold-out {
          background: $calendar-sold-out-day-background-color;

          &:hover,
          &:focus {
            color: $calendar-disabled-day-text-color;
          }

          .date {
            color: $calendar-sold-out-day-text-color;
            text-decoration: line-through;
          }

          .price {
            text-decoration: line-through;
          }
        }
      }

      // Leaving here for now as this selector will most probably be needed after a review
      // &:has(.price),
      // &:has(.availability-status) {
      //   .date {

      //     font-size: 14px;
      //   }
      // }
    }
  }

  .annotation-legend {
    border-top: $calendar-divider;
    padding-top: 16px;
    margin-top: 16px;
    margin-bottom: 0;

    @include mobileExperience {
      padding: 8px 16px 10px;
      margin-top: 0;
      box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.08);
      background: $calendar-legend-mobile-background-color;

      .annotation-item:not(:last-child) {
        margin-bottom: 0px;
      }
    }
  }
}

.date-picker input.date {
  max-width: 20em;
}

.quantity-first {
  .datepicker-inline-container {
    width: 100%;
  }
}

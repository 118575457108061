.stepper {
  padding: 0;
  border: none;
  border-radius: var(--control-border-radius);

  &.active {
    > .count {
      background-color: $steppers-3-count-background-active-color;
      color: $steppers-3-count-active-color;
    }
  }

  > .count,
  > button {
    height: $steppers-3-box-size;
    width: $steppers-3-box-size;
  }

  > .count {
    border: $steppers-3-count-border;
    border-top: $steppers-3-border;
    border-bottom: $steppers-3-border;
    background-color: $steppers-3-count-background-color;
    line-height: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  > button {
    border-radius: 0;
    background-color: $steppers-3-button-background-color;
    border: $steppers-3-border;
    padding: 0;

    &.less {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &.more {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    &:not([disabled]):hover,
    &:not([disabled]):focus {
      background-color: $steppers-3-button-background-hover-color;
      border-color: $steppers-3-border-hover-color;

      > svg {
        fill: $steppers-3-hover-icon-color;
      }
    }

    &[disabled] {
      opacity: 1;

      > svg {
        fill: $steppers-3-disabled-icon-color;
      }
    }

    > svg {
      fill: $steppers-3-icon-color;
      width: 14px;
    }
  }
}

.badge {
  background-color: $primary-color;
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  padding-top: 2px;

  &.info {
    background-color: $info-color;
  }

  &.warning {
    background-color: $warning-color;
  }

  &.promo {
    background-color: $promo-color;
  }

  .badges & {
    margin-left: 4px;
  }
}

.session {
  .badge {
    margin-left: 4px;

    &.increase {
      width: 15px;
      height: 15px;
      text-align: center;
      font-size: 11px;
      color: white;
      line-height: 1;
      top: 4px;
    }
  }
}

@import 'variables';

/***
Placeholders aren't included in the CSS output, they are style declarations used only with @extend.
- Our shared stylesheets route these generic placeholders to actual CSS selectors.
- Our tenant-specific stylesheets extend these placeholders to match the tenant's theme.
***/

// Main content container - Used in header, footer, and main content areas
%content-container {
  max-width: $site-max-width;
  width: 100%;
  margin: 0 auto;
  @include responsive(padding-left, $spacing-s, $spacing-l);
  @include responsive(padding-right, $spacing-s, $spacing-l);
}

// Actions element
%actions {
  padding-top: $spacing-xl;
  margin-top: $spacing-xl;
  border-top: $divider;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}

// Buttons and links
%link {
  text-decoration: none;
  color: $link-color;
  cursor: pointer;

  &:hover,
  &:focus {
    color: $link-hover-color;
  }
}

%button {
  font: $button-font;
  color: white;
  display: inline-block;
  padding: $spacing-xs $spacing-s;
  text-decoration: none;
  text-align: center;
  border-radius: 0;
  border: none;
  white-space: nowrap;
  line-height: 1;
  height: auto;

  &:not([disabled]) {
    cursor: pointer;
  }

  &:hover,
  &:focus {
    text-decoration: none;
  }

  &[disabled] {
    @extend %disabled-button;
  }
}

%disabled-button {
  opacity: 0.2;
  pointer-events: none;
}

%primary-button {
  @extend %button;
  background-color: $primary-color;

  &:hover,
  &:focus {
    @extend %primary-button-hover;
  }
}

%primary-button-hover {
  background-color: $secondary-color;
}

%secondary-button {
  @extend %button;
  background-color: $secondary-color;
  box-shadow: none;

  &:hover,
  &:focus {
    @extend %secondary-button-hover;
  }

  &.selected,
  &.active {
    @extend %secondary-button-active;
  }
}

%secondary-button-hover {
  background-color: $primary-color;
  color: white;
}

%secondary-button-active {
  background-color: $primary-color;
  color: white;
}

%back-button {
  font: $button-font;
  color: $link-color;
  text-transform: none;

  &:hover,
  &:focus {
    color: $link-hover-color;
  }
}

@keyframes rotate {
  to {
    transform: rotate(1turn);
  }
}

// TODO: Show loading spinner on all submit buttons
%loading-spinner {
  &:after,
  &:before {
    content: '';
    border-radius: 100%;
    background-color: transparent;
    animation: rotate 0.6s linear infinite;
  }

  &:after {
    border: 2px solid transparent;
    border-top-color: currentColor;
  }

  &:before {
    border: 2px solid currentColor;
    opacity: 0.2;
  }
}

// Text inputs
%input {
  font: $input-font;
  background-color: white;

  $input-height: 48px;
  $input-border-width: 1px;
  // TODO Keep vertical rhythm by setting a height.
  // TODO Make the height configurable?
  //height: $input-height - 2 * $input-border-width;

  padding: 12px 16px;
  border: $input-border-width solid $medium-gray;
  border-radius: 0;
}

%input-invalid {
  border-color: $error-color;
}

%input-hover {
  border-color: $dark-gray;
}

%input-focus {
  &,
  &:hover {
    outline: 2px solid $input-focus-color;
    outline-offset: -2px;
  }
}

%label {
  font: $label-font;
  display: block;
  margin-bottom: $spacing-xs;
}

// Headings & paragraphs
%h1,
%h2,
%h3,
%h4,
%h5,
%h6 {
  font: $heading-font;
  line-height: 1.2;
  color: $heading-color;
}

%p {
  font: $body-font;
  color: $body-color;
}

%h1 {
  font-size: 40px;
  margin-bottom: $spacing-m;
  margin-top: 0;
  @include mobileExperience {
    font-size: 24px;
  }
}

%h2 {
  font-size: 26px;
  margin-bottom: 20px;
  @include mobileExperience {
    font-size: 20px;
  }
}

%h3 {
  font-size: 20px;
  margin-bottom: 20px;
}

%h4 {
  font-size: 18px;
  margin-bottom: 20px;
}

%h5 {
  font-size: 16px;
  margin-bottom: 20px;
}

%h6 {
  font-size: 16px;
  margin-bottom: 20px;
}

.filters {
  margin-bottom: $spacing-xl;
  display: flex;
  justify-content: space-between;
  gap: 16px;

  @include small {
    flex-direction: column;
  }

  &:empty {
    display: none;
  }

  a {
    @extend %secondary-button;
    margin-right: $spacing-xs;
    margin-bottom: $spacing-xs;
  }

  .date-filter {
    flex-shrink: 0;
  }

  .category-filter {
    display: flex;
    align-items: center;
    flex-grow: 1;

    &.show-buttons {
      flex-wrap: wrap;
    }

    label {
      white-space: nowrap;
    }

    select {
      @extend %secondary-button;
      text-align: left;
      padding-left: 16px;
      max-width: 200px;
      margin-left: 12px;

      @include small {
        max-width: none;
      }
    }
  }

  .date-filter {
    @include small {
      display: flex;
    }
    .filter {
      @include small {
        flex-grow: 1;
      }

      &:first-child {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      &:nth-child(2) {
        border-radius: 0;
      }

      &:last-child {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }
}

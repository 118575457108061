.home-route {
  .options {
    @include smallAndMedium {
      > section {
        border-bottom: $divider;
      }
    }



    @include large {
      > section:not(.first) {
        border-left: $divider;
      }
    }
  }
}

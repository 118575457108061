.checkout-route {
  // Select the form actions, but not the apply-gift-card actions.
  .main > .actions {
    @extend %actions;
    justify-content: flex-end;
  }

  .form-wrapper {
    @include responsive(padding-left, $spacing-s, 0);
    @include responsive(padding-right, $spacing-s, 0);
  }

  .members-banner {
    margin-bottom: 24px;
  }

  .checkout {
    h2 {
      @extend %h4;
      line-height: 26px;
      margin-top: 32px;
      margin-bottom: 16px;
    }

    .payment-details,
    .form-input.text,
    .form-input.email,
    .form-input.tel,
    .form-input.select,
    .form-input.password,
    .form-input.number,
    .form-input.date {
      max-width: $identity-form-input-width;

      @include smallAndMedium {
        max-width: none;
      }
    }
  }

  .apply-gift-cards,
  .apply-promo-codes-checkout {
    margin-top: $spacing-m;

    button {
      background: none;
      border: none;
      font-size: inherit;
      font-weight: inherit;
      font-family: inherit;
      @extend %link;
      box-shadow: none;
    }

    button.apply-card,
    button.apply-code {
      padding: 16px 0;
    }

    .gift-card-number-field {
      small.optional {
        display: none;
      }
    }

    .add-card-button,
    .add-promo-button {
      svg {
        fill: currentColor;
      }
    }

    .applied-code svg path {
      stroke: $body-color;
    }

    .add-card-form .form-container {
      align-items: flex-end;

      label.form-input {
        margin-bottom: 0;
      }

      button.apply-card {
        margin: 0 16px;
      }
    }
  }

  .applied-cards {
    h4 {
      @extend %label;
    }

    .applied-card {
      .code-wrapper {
        @extend %input;
      }
    }
  }

  .purchaser-details,
  .payment,
  .terms-conditions {
    margin-bottom: 57px;

    &:last-child {
      @include mobileExperience {
        margin-bottom: 0;
      }
    }
  }

  .form.checkout > :first-child > h2 {
    margin-top: 0;
  }

  .upsell {
    @include large {
      .radio-items {
        display: flex;

        .radio-item {
          flex-basis: 100%;
        }
      }
    }
  }

  .identity-form {
    label.form-input.gift-aid-checkbox,
    label.form-input.checkbox:not(:last-child) {
      margin-bottom: 24px;
    }
  }
}

.mobile-checkout-heading {
  border-bottom: $divider;
}

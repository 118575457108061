@import 'todo/buttons';

a {
  @extend %link;
}

button {
  touch-action: manipulation;

  &:focus:not(:focus-visible) {
    outline: none;
  }
}

a.button {
  @extend %primary-button;

  &:hover,
  &:focus {
    @extend %primary-button-hover;
  }
}

a.button.secondary {
  @extend %secondary-button;

  &:hover,
  &:focus {
    @extend %secondary-button-hover;
  }
}

button:not([disabled]) {
  cursor: pointer;
}

button.primary,
.cart button.primary.purchase {
  @extend %primary-button;
}

button.secondary,
button.selectable,
.radio-group .radio-item.selectable,
.upsell label.radio-item.form-input.selectable.ticket-type {
  @extend %secondary-button;
  vertical-align: top;
  height: auto;
}

.radio-group .radio-item.selectable .label {
  color: inherit;
}

.radio-group .radio-item.selectable {
  height: $selectable-height;
}

button.navigate-back {
  @extend %back-button;
}

button.link {
  @extend %p;
  color: $link-color;
  background-color: transparent;
  border: none;
  padding: 0;

  &:hover,
  &:focus {
    color: $link-hover-color;
  }
}

.mobile-footer {
  button.primary {
    &.processing,
    &.submitting {
      @extend %loading-spinner;
      display: flex;
      justify-content: center;
      align-items: center;

      &:before,
      &:after {
        display: block;
        width: 15px;
        height: 15px;
      }

      &:before {
        order: 1;
        margin-left: -15px;
      }

      &:after {
        margin-left: 12px;
      }
    }
  }
}

.select-seats {
  .find-seats,
  .change-seats {
    &.processing,
    &.submitting {
      @extend %loading-spinner;
      display: flex;
      justify-content: center;
      align-items: center;

      &:before,
      &:after {
        display: block;
        width: 15px;
        height: 15px;
      }

      &:before {
        order: 1;
        margin-left: -15px;
      }

      &:after {
        margin-left: 12px;
      }
    }
  }
}

.content-detail-page {
  .show-benefits {
    svg {
      stroke: $link-color;
    }
  }
}

.tabs {
  @extend %button;
  display: grid;
  gap: 6px;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  background-color: rgba(0, 0, 0, 0.04);
  border: 0;
  padding: 4px;
  margin: 16px 0;

  .dark-theme & {
    background-color: rgba(255, 255, 255, 0.07);
  }

  .button {
    @extend %button;
    min-height: 40px;
    background-color: transparent;
    border: 0;
    padding: 4px 8px;
    color: $heading-color;
    font-family: $primary-font;
    font-size: 14px;
    font-weight: 600;
    line-height: 150%;
    overflow: hidden;

    &:hover,
    &:focus {
      background-color: rgba(0, 0, 0, 0.03);

      .dark-theme & {
        background-color: rgba(255, 255, 255, 0.03);
      }
    }

    > span {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &.selected,
    &.selected:hover,
    &.selected:focus {
      background-color: #fff;
      box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.2);

      .dark-theme & {
        background-color: rgba(255, 255, 255, 0.07);
      }
    }

    &:not(.selected) {
      box-shadow: none;
    }

    &[disabled] {
      opacity: 0.5;
      pointer-events: unset;

      &:hover,
      &:focus {
        background-color: transparent;
      }
    }
  }

  .subtitle {
    display: block;
    font-size: 11px;
    color: $body-color;
    font-weight: 400;
    margin-top: -5px;
    text-shadow: none;
  }
}

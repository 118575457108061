// TODO Should this be a `.selected` state of `.selectable-button`?
.selected-date-time {
  display: grid;
  grid-template-columns: 1fr auto;
  column-gap: 5px;
  row-gap: 3px;
  grid-template-rows: 100% 100%;
  padding: 12px 14px;
  flex-basis: 100%;
  height: 64px;
  align-items: center;
  line-height: 1;
  background: $white;

  .dark-theme & {
    background: transparent;

    .remaining:not(.danger),
    .session-price {
      color: rgba(255,255,255,0.87);
    }

    .session-time {
      color: rgba(255,255,255,0.87)
    }
  }

  @include desktopExperience {
    flex-basis: calc(50% - 6px);
  }

  &.show-availability {
    grid-template-rows: 50% 50%;

    .change-btn {
      grid-row: 1 / 3;
      grid-column: 2;
      margin-bottom: 2px;
    }
  }

  &,
  &:focus {
    border: 2px solid $primary-color;
  }

  .change-btn {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .btn {
      padding: 0 12px;
      font-size: 14px;
      height: 32px;
      box-shadow: none;
    }
  }

  label {
    font-weight: 600;
    font-size: inherit;
  }

  .availability-status {
    grid-column: 1;
  }
}

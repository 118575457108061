/***
  @deprecated Tenant themes can override these variables to match the themes colours, spacing etc.
***/

// Fonts
$primary-font: var(--primary-font, 'Arial, sans-serif') !default;
$secondary-font: var(--secondary-font, 'Georgia, serif') !default;

// Colors
$black: var(--black, #000) !default;
$white: var(--white, #fff) !default;

$very-dark-gray: var(--very-dark-gray, #0e0e0e) !default;
$dark-gray: var(--dark-gray, #222) !default;
$medium-gray: var(--medium-gray, #666) !default;
$light-gray: var(--light-gray, #e3e3e3) !default;
$very-light-gray: var(--very-light-gray, #f1f1f1) !default;

$green: var(--green, #8cc344) !default;

// Fonts
$body-font: var(--body-font, normal 16px/1.2 $primary-font) !default;
$smaller-font: var(--body-font, normal 14px/1.2 $primary-font) !default;
$button-font: var(--button-font, normal 16px/1 $primary-font) !default;
$input-font: var(--input-font, normal 16px/1.2 $primary-font) !default;
$heading-font: var(--heading-font, normal 16px/1 $secondary-font) !default;
$label-font: var(--label-font, normal 16px/1 $secondary-font) !default;

// Text colors
$icon-color: var(--icon-color, $white) !default; // Default colour for SVG icons if no colour is specified
$link-color: var(--link-color, $secondary-color) !default;
$link-hover-color: var(--link-hover-color, $primary-color) !default;
$heading-color: var(--heading-color, $black) !default; // Default colour for all headings
$body-color: var(--body-color, $medium-gray) !default; // Default colour for all body text and paragraphs

// Borders
$divider: var(--divider, $light-gray solid 1px) !default; // Used for horizontal rules

// Form inputs
$input-focus-color: var(--input-focus-color, #1566ce) !default;
$select-arrow-color: var(--select-arrow-color, $primary-color) !default;
$identity-form-input-width: 560px !default;

// Radio buttons
$radio-button-background-color: var(--radio-button-background-color, transparent) !default;
$radio-button-border-color: var(--radio-button-border-color, $primary-color) !default;
$radio-button-hover-border-color: var(--radio-button-hover-border-color, $radio-button-border-color) !default;
$radio-button-checked-background-color: var(--radio-button-checked-background-color, transparent) !default;
$radio-button-checked-border-color: var(--radio-button-checked-border-color, $primary-color) !default;
$radio-button-selected-color: var(--radio-button-selected-color, $primary-color) !default;
$radio-button-border-width: var(--radio-button-border-width, 1px) !default;

// Datepicker input
$datepicker-placeholder-color: var(--datepicker-placeholder-color, $dark-gray) !default;
$datepicker-placeholder-hover-color: var(--datepicker-placeholder-hover-color, $dark-gray) !default;
$datepicker-icon-color: var(--datepicker-icon-color, $primary-color) !default;
$datepicker-icon-color-hover: var(--datepicker-icon-color-hover, $primary-color) !default;
$datepicker-icon-color-active: var(--datepicker-icon-color-active, $primary-color) !default;
$datepicker-icon-color-active-hover: var(--datepicker-icon-color-active-hover, $primary-color) !default;

// Calendar input
$calendar-button-color: var(--calendar-button-color, #ffffff) !default;
$calendar-button-icon-color: var(--calendar-button-icon-color, #282b37) !default;
$calendar-day-text-color: var(--calendar-day-text-color, #282b37) !default;
$calendar-today-background-color: var(--calendar-today-background-color, #ddd) !default;
$calendar-divider: var(--calendar-divider, 1px dashed #cccccc) !default;
$calendar-month-text-color: var(--calendar-month-text-color, #282b37) !default;
$calendar-legend-text-color: var(--calendar-legend-text-color, #525a6c) !default;

$calendar-disabled-day-text-color: var(--calendar-disabled-day-text-color, #bfc1ca) !default;
$calendar-selected-day-outline-color: var(--calendar-selected-day-outline-color, $primary-color) !default;

// Cart
$cart-divider-color: var(--cart-divider-color, $light-gray) !default;
$cart-divider: var(--cart-divider, 1px dashed $cart-divider-color) !default;
$cart-background-color: var(--cart-background-color, #fff) !default;
$cart-item-removal-overlay-color: var(--cart-item-removal-overlay-color, $cart-background-color) !default;
$cart-remove-button-icon-color: var(--cart-remove-button-icon-color, $primary-color) !default;
$cart-remove-button-background-color: var(--cart-remove-button-background-color, rgba(0, 0, 0, 0.06)) !default;
$cart-price-background-color: var(--cart-price-background-color, transparent) !default;

// Selectable buttons
// TODO: Can we remove this now that we've removed the old absolutely positioned capacity label?
$selectable-height: var(--selectable-height, 40px) !default;

// Messages
$info-color: var(--info-color, #3b73d8) !default;
$info-background-color: var(--info-background-color, #e6f0fb) !default;

$success-color: var(--success-color, #006400) !default;
$success-background-color: var(--success-background-color, #f5fffa) !default;

$warning-color: var(--warning-color, #ffa500) !default;
$warning-background-color: var(--warning-background-color, #fff6e6) !default;

$error-color: var(--error-color, #eb1c26) !default;
$error-background-color: var(--error-background-color, #fee) !default;

$promo-color: var(--promo-color, #9877b0) !default;
$promo-background-color: var(--promo-background-color, rgba(152, 119, 176, 0.15)) !default;

// Members Menu
$members-menu-icon-color: var(--members-menu-icon-color, white) !default;
$members-menu-text-color: var(--members-menu-text-color, $secondary-color) !default;
$members-menu-username-color: var(--members-menu-username-color, $body-color) !default;

// Steppers 3
$steppers-3-box-size: var(--steppers-3-box-size, 48px) !default; // Width/height of stepper boxes
$steppers-3-border: var(--steppers-3-border, 1px solid $light-gray) !default;
$steppers-3-border-hover-color: var(--steppers-3-border-hover-color, $medium-gray) !default;
$steppers-3-button-background-color: var(
  --steppers-3-button-background-color,
  $very-light-gray
) !default; // Stepper buttons background color
$steppers-3-button-background-hover-color: var(--steppers-3-button-background-hover-color, $very-light-gray) !default;
$steppers-3-icon-color: var(--steppers-3-icon-color, $medium-gray) !default; // Plus and minus icons color
$steppers-3-disabled-icon-color: var(--steppers-3-disabled-icon-color, $light-gray) !default;
$steppers-3-hover-icon-color: var(--steppers-3-hover-icon-color, $steppers-3-icon-color) !default;
$steppers-3-count-background-color: var(--steppers-3-count-background-color, transparent) !default;
$steppers-3-count-border: var(--steppers-3-count-border, none) !default;
$steppers-3-count-background-active-color: var(
  --steppers-3-count-background-active-color,
  $steppers-3-button-background-color
) !default;
$steppers-3-count-active-color: var(--steppers-3-count-active-color, $steppers-3-icon-color) !default;

// Cancel free tickets
$cancel-free-tickets-border-color: var(
  --cancel-free-tickets-border-color,
  $medium-gray
) !default; // Border color of the cancel free tickets table
$cancel-free-tickets-background-color: var(
  --cancel-free-tickets-background-color,
  $light-gray
) !default; // Background color of ticket rows in the cancel free tickets table

// Edit order
$edit-order-session-border-color: var(
  --edit-order-session-border-color,
  $light-gray
) !default; // Border color of session cards on the edit order route
$edit-order-session-border: var(
  --edit-order-session-border,
  1px solid $edit-order-session-border-color
) !default; // Border of session cards on the edit order route
$edit-order-ticket-background-color: var(
  --edit-order-ticket-background-color,
  $very-light-gray
) !default; // Background color of tickets in the dropdown on the edit order route

// Completed page
$completed-checkmark-background-color: var(--completed-checkmark-background-color, $green) !default;

// Back button
$back-button-background-color: var(--back-button-background-color, white) !default;
$back-button-shadow: var(--back-button-shadow, 0px 0px 5px rgba(0, 0, 0, 0.4)) !default;
$back-button-icon-color: var(--back-button-icon-color, #282b37) !default;

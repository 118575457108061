@mixin fullHeightTeaserImages() {
  @include mediumAndLarge {
    .event-listing article.event {
      figure {
        height: 100%;

        img {
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
}

@mixin twoColumnEventsList($spacing: $spacing-l) {
  .event-listing {
    @include mediumAndLarge {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: $spacing;
    }

    article.event {
      @include mediumAndLarge {
        &:nth-child(odd) {
          grid-column: 1;
        }

        &:nth-child(even) {
          grid-column: 2;
        }

        figure {
          margin-right: 0;
        }
      }
    }

    figure {
      grid-column: 1;

      img {
        margin-bottom: 16px;
      }
    }

    .title-link {
      grid-column: 1;
      margin-top: 24px;

      h2 {
        margin-bottom: 20px;
      }
    }

    .subtitle {
      grid-column: 1;
    }

    .description {
      grid-column: 1;
    }

    div.button.primary {
      grid-column: 1;
    }
  }
}

// TODO Should this be part of identity_form config instead?
@mixin unwrapIdentityFields($left, $right, $sizeLeft) {
  @include mediumAndLarge {
    .identity-form > .field-#{$left} {
      flex-basis: calc(#{100% * $sizeLeft} - 8px);
    }

    .identity-form > .field-#{$right} {
      flex-basis: calc(#{100% - 100% * $sizeLeft} - 8px);
    }
  }
}

@mixin blurModalBackground() {
  .modal:before {
    backdrop-filter: blur(2px);
  }
}

@mixin darkThemeSectionHeader() {
  .section-header {
    background-color: rgba(255, 255, 255, 0.08);
  }
}

@mixin darkThemeCheckoutPromoCodes() {
  @include mobileExperience() {
    .applied-code {
      border: 1px solid $body-color;
    }
  }
}

@mixin eventListingButtonOutsideContentColumn() {
  @include large {
    .event-listing-item .button.primary {
      grid-column: 3;
      grid-row: 1/20;
      margin-left: 16px;
    }
  }
}

@mixin membershipModalImage($width: 45%) {
  &:before {
    content: '';
    background-size: cover;
    background-position: center;
    width: $width;
    min-height: 379px;
    @content;
  }
}

@mixin hover {
  &:hover,
  &:focus,
  &.faux-hover {
    @content;
  }
}

@mixin dashedBottomBorder($color: $light-gray) {
  border-bottom: 1px solid transparent;
  border-image: repeating-linear-gradient(90deg, $color, $color 9px, transparent 9px, transparent 13px) 1;
}

form.buy-membership {
  margin-top: $spacing-xl;

  .membership-level,
  .auto-renew {
    .radio-item .label {
      @extend %h3;
      margin-bottom: $spacing-xs;
    }
  }

  @include desktopExperience {
    .membership-level .radio-items,
    .auto-renew .radio-items,
    .membership-roles {
      padding: 0 16px;
    }
  }

  .membership-level,
  .membership-roles,
  .auto-renew {
    margin-bottom: $spacing-xl;
  }

  .member-names {
    .radio-items {
      display: flex;

      .radio-item {
        margin-bottom: 0;
        margin-right: 16px;
      }
    }
  }

  label.member-names {
    > .label {
      @extend %h3;
      margin-bottom: $spacing-s;
      display: block;
    }
  }

  .membership-roles {
    .member-names,
    .unnamed-members,
    .name-fields {
      margin-bottom: $spacing-l;
    }
  }

  .name-fields {
    label.form-input {
      max-width: 580px;
    }
  }
}

/***
  @deprecated These should never be overridden in SCSS, they are read-only
  They are just convenience properties for CSS properties configured for each theme in theme.yml
***/

$primary-color: var(--primary-color);
$primary-color-darker: var(--primary-color-darker);
$primary-contrast-color: var(--primary-contrast-color);
$secondary-color: var(--secondary-color);

// Spacing
$u: 8px;
$spacing-xs: $u;
$spacing-s: $u * 2;
$spacing-m: $u * 3;
$spacing-l: $u * 4;
$spacing-xl: $u * 5;

// Site max width
$site-max-width: var(--site-max-width, 2 * $spacing-l + 1160px);

// Calendar input
$calendar-button-border: var(--calendar-button-border);
$calendar-availability-text-color: var(--calendar-availability-text-color);
$calendar-price-text-color: var(--calendar-price-text-color);
$calendar-cell-border: var(--calendar-cell-border);
$calendar-legend-mobile-background-color: var(--calendar-legend-mobile-background-color);

$calendar-hover-day-background-color: var(--calendar-hover-day-background-color);
$calendar-hover-day-text-color: var(--calendar-hover-day-text-color);
$calendar-disabled-day-background-color: var(--calendar-disabled-day-background-color);
$calendar-sold-out-day-text-color: var(--calendar-sold-out-day-text-color);
$calendar-sold-out-day-background-color: var(--calendar-sold-out-day-background-color);

// Dialog
$dialog-background-color: var(--dialog-background-color);
$dialog-header-text-color: var(--dialog-header-text-color);
$dialog-header-icon-color: var(--dialog-header-icon-color);
$dialog-header-background: var(--dialog-header-background);
$dialog-header-border: var(--dialog-header-border);

// Mobile footer
$mobile-footer-background-color: var(--mobile-footer-background-color);
$mobile-footer-text-color: var(--mobile-footer-text-color);
$mobile-footer-cart-button-background-color: var(--mobile-footer-cart-button-background-color);
$mobile-footer-cart-button-hover-background-color: var(--mobile-footer-cart-button-hover-background-color);
$mobile-footer-cart-button-border: var(--mobile-footer-cart-button-border);
$mobile-footer-cart-button-icon-color: var(--mobile-footer-cart-button-icon-color);
$mobile-footer-cart-badge-background-color: var(--mobile-footer-cart-button-hover-background-color);
$mobile-footer-cart-badge-text-color: var(--mobile-footer-cart-badge-text-color);
$mobile-footer-price-text-color: var(--mobile-footer-price-text-color);

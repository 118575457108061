.complete {
  picture.background {
    width: 100vw;
    position: relative;
    left: 50%;
    margin-left: -50vw;
    display: block;

    img {
      width: 100%;
      max-width: none;
      object-fit: cover;
      object-position: center;

      @include responsiveProperty('height', 270px, 470px, 470px);
    }
  }

  .body-inner .site-content-inner {
    padding-top: 0;
    margin-top: 0;
  }

  .main {
    text-align: center;
    background-color: white;
    max-width: 712px;
    margin: 0 auto;
    position: relative;
    @include responsiveProperty(
      'margin-top',
      var(--completed-mobile-offset),
      var(--completed-offset),
      var(--completed-offset)
    );
    @include responsiveProperty('margin-bottom', $spacing-l, 40px, 100px);

    &:before {
      $size: 100px;
      content: ' ';
      background: $completed-checkmark-background-color no-repeat center center/35px icon('checkmark');
      display: inline-block;
      width: $size;
      height: $size;
      margin-top: -$size * 0.5;
      line-height: $size;
      border-radius: $size;
      font-size: $size * 4 * 0.2;
      text-align: center;

      @include small() {
        $small-size: 78px;
        width: $small-size;
        height: $small-size;
        margin-top: -37px;
        background-size: 25px;
      }
    }

    h1 {
      text-align: center;
      max-width: unset;
      margin: $spacing-l 0;
      @include responsiveProperty('font-size', 20px, 33px, 33px);
      line-height: 1;
      border: 0;
      color: $dark-gray;

      span {
        font-size: 55px;
        line-height: 1.2;
        display: block;
        margin-bottom: $spacing-m;
        color: $secondary-color;
      }
    }

    code.order-number {
      display: block;
      font-size: 28px;
      line-height: 1.2;
      letter-spacing: -1px;
      margin: 0;
      font-family: $secondary-font;
      color: $secondary-color;
      font-feature-settings: 'lnum' 1;

      @include small() {
        line-height: 24px;
        font-size: 20px;
      }
    }

    .qr-codes {
      @include desktopExperience {
        display: none;
      }
    }

    ul.links {
      padding: 0;
      margin: 0;
      list-style: none;

      li:not(:last-of-type) {
        margin-bottom: 16px;
      }
    }

    .ticket-orders {
      margin: 24px 0;

      @include large {
        margin: 32px 0;
      }
    }

    .ticket-order {
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 0;
      }

      p {
        margin: 0;
      }
    }

    .qr-code img {
      width: 210px;
      margin: 0 auto;
    }
  }
}

.password-requirements {
  background-color: rgba(0, 0, 0, 0.03);
  border: 1px solid $light-gray;
  padding: 14px 16px;
  margin-bottom: 24px;
  max-width: 560px;
  font: $smaller-font;
  line-height: 1.5;

  .dark-theme & {
    background-color: rgba(255, 255, 255, 0.03);
    border-color: rgba(255, 255, 255, 0.15);
  }

  &.error {
    background-color: #FEF3F4;
    border-color: #FFD7DC;

    .dark-theme & {
      background-color: $error-background-color;
      border-color: $error-color;
    }
  }

  &.success {
    background-color: #F6FCF1;
    border-color: #E2F2D0;

    .dark-theme & {
      background-color: $success-background-color;
      border-color: $success-color;
    }
  }

  .circle-icon {
    width: 10px;
    height: auto;
  }

  .status-icon {
    display: inline-block;
    width: 15px;
    text-align: center;
    margin-right: 6px;
  }

  .times-icon {
    width: 16px;
    height: auto;
    position: relative;
    top: 4px;
  }

  .checkmark-icon {
    width: 12px;
    height: auto;
  }

  ul {
    padding: 0;
    margin: 0;
    list-style: none;

    li {
      padding: 0;
      margin: 0;
      clear: both;

      &.default {
        color: $body-color;
      }

      &.success {
        color: #62b462;

        > .status-icon path {
          stroke: currentColor;
        }
      }

      &.error {
        color: #f94750;

        > .status-icon path {
          stroke: currentColor;
        }
      }
    }

    ul {
      margin-left: 18px;

      .circle-icon {
        width: 8px;
      }

      .checkmark-icon {
        width: 8px;
      }
    }
  }
}

.reset-password-route {
  .form-input.password {
    span.message.invalid {
      display: none;
    }
  }
}

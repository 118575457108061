@import '../../styles/defaults/css-properties';

// Colors from config
$primary-color: var(--primary-color);
$primary-color-darker: var(--primary-color-darker);
$secondary-color: var(--secondary-color);
$secondary-color-darker: var(--secondary-color-darker);
$header-background: var(--header-background);
$members-links-color: var(--member-links-color);
$members-link-color-hover: var(--member-links-color-hover);
$members-menu-icon-color: var(--member-links-color);
$cart-header-background-color: var(--cart-header-background-color);
$cart-header-text-color: var(--cart-header-text-color);
$cart-header-border: var(--cart-header-border);
$cart-border: var(--cart-border);
$footer-background-color: var(--footer-background-color);
$footer-text-color: var(--footer-text-color);
$footer-links-color: var(--footer-links-color);
$footer-link-color-hover: var(--footer-links-color-hover);

$black: var(--black);
$dark-gray: var(--dark-gray);
$medium-gray: var(--medium-gray);
$light-gray: var(--light-gray);
$very-light-gray: var(--very-light-gray);

// Variables
$heading-font: 600 16px/1 var(--secondary-font) !default;
$body-font: 400 16px/1.5 var(--primary-font) !default;

$shade-gray: #eceeef;
$order-summary-background: #f5f5f5;
$medium-gray-hover: #bfc1ca;

$button-shadow-color: rgba(0, 0, 0, 0.07);

$selectable-height: 48px;

$datepicker-icon-color: currentColor;
$datepicker-icon-color-active: currentColor;
$datepicker-icon-color-hover: currentColor;

$secondary-button-text-color: var(--secondary-button-text-color);
$secondary-button-background: var(--secondary-button-background);
$secondary-button-background-hover: var(--secondary-button-background-hover);
$secondary-button-border: var(--secondary-button-border);
$secondary-button-border-hover: var(--secondary-button-border-hover);
$secondary-button-background-active: var(--secondary-button-background-active);
$secondary-button-text-color-active: var(--secondary-button-text-color-active);

$link-color: $primary-color;
$link-hover-color: $primary-color-darker;

// Defaults
@import '../../styles/defaults/placeholders';
@import '../../styles/defaults/variables';
// Don't import ../../styles/defaults/custom-properties; Fallback theme sets custom properties in JS
@import '../../styles/helpers/index';

// Placeholders
%button {
  border-radius: var(--control-border-radius);
}

%primary-button {
  padding: 16px 27px;
  box-shadow: 0px 1px 6px 0px $button-shadow-color;
  transition: background-color 200ms ease-out;
  color: var(--primary-contrast-color);
}

%primary-button-hover {
  background-color: $primary-color-darker;
}

%secondary-button {
  border: $secondary-button-border;
  padding: 14px 27px;
  box-shadow: 0px 1px 6px 0px $button-shadow-color;
  transition: border 200ms ease-out, background-color 200ms ease-out;
  color: $secondary-button-text-color;
  background-color: $secondary-button-background;
}

%secondary-button-hover {
  color: $secondary-button-text-color;
  background-color: $secondary-button-background-hover;
  border-color: $secondary-button-border-hover;
}

%secondary-button-active {
  border-color: $secondary-color;
  box-shadow: none;
  color: $secondary-button-text-color-active;
  background-color: $secondary-button-background-active;
}

%label {
  font-weight: 600;
  margin-bottom: 4px;
  color: var(--label-color, $dark-gray);

  .dark-theme & {
    color: rgba(255, 255, 255, 0.87);
  }
}

%input {
  border-radius: min(var(--control-border-radius), 5px);
  padding: 13px 16px;
  color: $body-color;

  .light-theme & {
    border: 1px solid $light-gray;

    &:disabled {
      color: #b5b5b5;
      background-color: #f7f7f7;
    }
  }

  .dark-theme & {
    background-color: rgba(255, 255, 255, 0.06);
    color: rgba(255, 255, 255, 0.87);
    border: 1px solid rgba(255, 255, 255, 0.2);

    &:disabled {
      color: rgba(255, 255, 255, 0.4);
      background-color: rgba(255, 255, 255, 0.06);
    }

    option {
      background-color: var(--page-background-color-lighter);
    }
  }

  &:disabled {
    opacity: 0.8;
  }
}

%input-hover {
  .light-theme & {
    border-color: $medium-gray;
  }

  .dark-theme & {
    border: 1px solid rgba(255, 255, 255, 0.4);
  }
}

// Base elements
@import '../../styles/page';
@import '../../styles/elements';
@import '../../styles/links-and-buttons';
@import '../../styles/form-inputs';
@import '../../styles/modals';
@import '../../styles/messages';
@import '../../styles/badges';

// Components
@import '../../styles/header';
@import '../../styles/footer';
@import '../../styles/filters';
@import '../../styles/actions';
@import '../../styles/cart';
@import '../../styles/selection';
@import '../../styles/steppers-3';
@import '../../styles/calendar';
@import '../../styles/members-menu';
@import '../../styles/manage-order';
@import '../../styles/resend-tickets';
@import '../../styles/legend';

// Routes
@import '../../styles/checkout';
@import '../../styles/completed';
@import '../../styles/event-list';
@import '../../styles/buy-membership';
@import '../../styles/gift-of-membership';
@import '../../styles/forgot-membership';
@import '../../styles/event';
@import '../../styles/home';
@import '../../styles/my-account';

// Theme
@import 'sass/styles';
@import 'sass/header';
@import 'sass/footer';

.dark-theme {
  @include blurModalBackground();
}

.content-detail-page > .main,
.reschedule-experience {
  .actions {
    @extend %actions;
  }
}

.set-email-form {
  .actions {
    @extend %actions;
    flex-direction: row;
  }
}

.edit-order .sticky-footer {
  @extend %actions;
  justify-content: flex-end;
  flex-direction: row;

  @include smallAndMedium {
    padding-top: 16px;
    flex-wrap: nowrap;
  }
}

.forgot-password-route,
.forgot-membership-route {
  form .actions {
    padding-bottom: $spacing-m;
    border-bottom: $divider;
  }
}

.selectable-button {
  cursor: pointer;
  padding: 13px 15px 12px 15px;
  line-height: 1;
  min-height: 64px;
  border: 1px solid rgba(0,0,0,0.09);
  font-family: inherit;
  position: relative;
  background-color: white;
  overflow: hidden;
  transition: border .2s ease-out, background-color .2s ease-out;
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.09);
  text-align: left;

  &.sold-out {
    box-shadow: none;
    border: 0;
  }

  .dark-theme & {
    &:not(.sold-out) {
      background-color: transparent;
      border-color: rgba(255,255,255,0.25);
      box-shadow: 0 1px 0 0 rgba(255,255,255,0.25);

      @include hover {
        border-color: rgba(255,255,255,0.5);;
        box-shadow: 0 1px 0 rgba(255,255,255,0.5);
        border-width: 1px;
      }
    }
  }

  &:not(.sold-out) {
    @include hover {
      border-color: rgba(0, 0, 0, 0.25);
      box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.25);
    }
  }
}

.cart {
  section.cart {
    background-color: $cart-background-color;
  }

  h2 {
    margin: 0;
    text-align: center;
    padding: 20px;
  }

  .line-item .content-wrapper {
    font-size: inherit;
  }

  .content {
    .empty-cart-message {
      margin: 0;
      padding: $spacing-m;
      text-align: center;
    }

    .visit-date {
      padding: $spacing-s;
    }

    .ticket-wrapper {
      padding: 22px $spacing-s 0;
      border-bottom: 0;

      .remove-cart-item-overlay {
        padding: $spacing-s;

        &:before {
          content: '';
          display: block;
          position: absolute;
          width: 100%;
          height: 100%;
          background-color: $cart-item-removal-overlay-color;
          opacity: 0.95;
          z-index: -1;
        }

        button {
          @extend %button;

          &.cancel-remove {
            @extend %secondary-button;
          }

          &.confirm-remove {
            @extend %primary-button;
          }
        }
      }
    }

    .ticket-date {
      margin-bottom: 0;
    }

    .ticket {
      padding-bottom: 22px;
      border-bottom: $cart-divider;

      header {
        margin-bottom: 12px;

        h4 {
          padding-right: 8px;
          margin-bottom: 0;
        }

        .price-wrapper {
          @extend %h4;
          margin-bottom: 0;
        }
      }
    }

    .ticket-type {
      margin-bottom: $spacing-xs;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .total-prices {
      padding: $spacing-s $spacing-s $spacing-m;
      background-color: $cart-price-background-color;

      .total-due {
        border-top: $cart-divider;
      }
    }

    .apply-promo-codes {
      border-top: $cart-divider;
      border-bottom: $cart-divider;

      button[type='reset'] svg {
        stroke: $link-color;
      }
    }

    .ticket-wrapper:last-child {
      .ticket {
        border-bottom: 0;
      }
    }

    .apply-promo-codes {
      .joint-input-button > .wrapper .promo-number-field {
        @extend %input;
      }
    }

    .type-count {
      display: flex;

      .count,
      .cross {
        display: inline-block;
        width: 1.5rem; // Approximate number to fit "99" number
        text-align: center;
        flex-shrink: 0;
      }

      .cross {
        margin-right: 5px;
      }

      .name {
        word-break: break-word;
      }
    }

    .total-prices {
      .line-item {
        &:not(.total-due) {
          h3 {
            @extend %p;
            margin-top: 0;
            margin-bottom: 0;
          }
        }

        &.payments {
          padding-top: 16px;
          margin-top: 16px;
          border-top: $cart-divider;
        }
      }
    }

    .total-due {
      padding-top: $spacing-m;

      .price-wrapper {
        @extend %h3;
        margin-bottom: 0;
      }
    }

    .ticket-wrapper {
      .actions button.remove {
        color: $link-color;
        display: block;
        margin: 0;
        padding: 0;
        font-size: 24px;
        width: 32px;
        height: 32px;
        background-color: $cart-remove-button-background-color;
        border-radius: 100%;

        &:hover,
        &:focus {
          color: $link-hover-color;
        }

        svg {
          stroke: $cart-remove-button-icon-color;
        }
      }
    }

    .add-promo-button {
      background-color: transparent;
      border: none;
      color: $link-color;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: $spacing-s;
      font-size: 16px;
      font-family: $primary-font;

      &[disabled] {
        opacity: 0.3;
        cursor: not-allowed;
      }

      svg {
        margin: 0;
        transition: transform 0.2s ease-in-out;
        border: 2px solid;
        border-radius: 100%;
        width: 20px;
        height: 20px;
        padding: 3px;
        fill: currentColor;
      }

      &:not([disabled]):hover,
      &:not([disabled]):focus {
        color: $link-hover-color;
      }
    }

    .add-promo-form {
      padding: $spacing-xs $spacing-s;

      .message.invalid {
        text-align: left;
      }
    }
  }

  button.primary.purchase.internal.processing {
    @extend %loading-spinner;
    display: flex;
    justify-content: center;
    align-items: center;

    &:before,
    &:after {
      display: inline-block;
      width: 20px;
      height: 20px;
      flex-shrink: 0;
      flex-grow: 0;
    }

    &:before {
      order: 2;
      margin-left: 20px;
    }

    &:after {
      order: 3;
      margin-left: -20px;
    }
  }
}

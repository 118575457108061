@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap');

@include mobileExperience {
  .mobile-footer,
  .sticky-footer {
    color: $mobile-footer-text-color;
    background-color: $mobile-footer-background-color;
    z-index: 1;

    .main-button-wrapper {
      button {
        width: 100%;
        height: 48px;
        padding-left: 5px;
        padding-right: 5px;
      }
    }

    // Mobile footer buttons are a standard size across all tenant themes, reset tenant-specific padding to 0.
    // For some reason this breaks Apple Pay buttons. So only do it for Apple Pay
    .pay-button-wrapper:not(.wallet-apple) button.primary.internal {
      padding: 0;
    }

    .cart-button {
      background: $mobile-footer-cart-button-background-color;
      border: $mobile-footer-cart-button-border;
      box-shadow: none;

      &:hover,
      &:focus {
        background: $mobile-footer-cart-button-hover-background-color;
      }
    }

    .svg-icon {
      stroke: $mobile-footer-cart-button-icon-color;
    }

    .count {
      color: $mobile-footer-cart-badge-text-color;
      background-color: $mobile-footer-cart-badge-background-color;
      font-family: 'Montserrat', sans-serif;
      letter-spacing: 0;
      text-shadow: 0 0 2px $mobile-footer-cart-badge-background-color;
    }

    .price,
    .free {
      color: $mobile-footer-price-text-color;
    }
  }
}

.site-footer-inner {
  @extend %content-container;
}

$modal-border-radius: 4px;

body.modal-open {
  overflow: hidden;
}

.modal {
  position: fixed;
  padding: 0;
  margin: 0;
  background-color: transparent;
  border: none;
  height: 100%;
  width: 100%;
  max-width: none;
  max-height: none;
  z-index: 10;
  top: 0;

  &::backdrop {
    display: none;
  }

  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    transition: opacity var(--modal-transition-duration) ease-out;
    opacity: 0;
  }

  &.open {
    &:before {
      opacity: 1;
    }

    @include desktopExperience {
      .modal-window {
        opacity: 1;
      }
    }
  }

  .modal-window {
    // Offset to prevent the modal header overlapped by Safari URL in landscape mode and to show other content behind the modal
    // Available as a CSS property so it can be used by child elements
    --mobile-modal-top-offset: 80px;
  }

  .modal-window {
    position: absolute;
    width: 100%;
    background-color: $dialog-background-color;
    box-shadow: 3px 5px 20px #333;
    z-index: 100;
    max-width: 960px;
    border-radius: $modal-border-radius;

    max-height: calc(100vh - var(--mobile-modal-top-offset));
    display: flex;
    flex-direction: column;

    .modal-body {
      overflow: auto;
    }

    @include desktopExperience {
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
    }
  }

  &.disabled {
    opacity: 0.2;

    &::after {
      background: transparent;
      content: '';
      position: absolute;
      z-index: 999;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }

  // Sizes
  &.sm .modal-window {
    max-width: 400px;
  }

  &.md .modal-window {
    max-width: 700px;
  }

  &.lg .modal-window {
    max-width: 960px;
  }

  &.sm,
  &.md,
  &.lg {
    @include mobileExperience {
      .modal-window {
        max-width: none;
      }
    }
  }

  .modal-header {
    .title {
      font-size: 26px;
      line-height: 1.2;
      padding: 0;
      margin: 0;
      flex-grow: 1;
      color: $dialog-header-text-color;
    }

    .close {
      border: 0;
      background: none;
      display: flex;
      align-items: center;
      justify-content: center;
      // Prevent white outline around shapes (esp when two shapes overlaps) in iOS Safari
      shape-rendering: crispEdges;
      padding: 0;

      .svg-icon {
        stroke: $dialog-header-icon-color;
        width: 30px;
        height: 30px;
      }
    }

    &.no-title {
      position: relative;
      right: 0;
      margin: 16px 16px 32px;
      padding: 0;
      font-size: 32px;
      line-height: 1;

      .close {
        .svg-icon {
          stroke: $primary-color;

          path {
            stroke-width: 4px;
          }
        }
      }

      @include mobileExperience {
        .close {
          @include iconButton;
          // Reset the button's position
          position: relative;
          top: 0;
          left: 0;
        }
      }

      @include desktopExperience {
        position: absolute;
        left: auto;
        right: 16px;
        top: 16px;
        margin: 0;
      }
    }

    &.has-title {
      display: flex;
      align-items: center;
      min-height: 72px;
      box-sizing: border-box;
      padding: 16px 32px;

      @include mobileExperience {
        padding: 16px;
      }
    }
  }
}

.modal-body {
  padding: 32px;

  @include mobileExperience {
    padding: 16px 16px 24px;
  }

  .actions {
    display: flex;
    justify-content: space-between;

    @include mobileExperience {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }

    button {
      @include mobileExperience {
        width: 100%;
      }
    }
  }
}

.modal-header.has-title {
  border-top-left-radius: $modal-border-radius;
  border-top-right-radius: $modal-border-radius;
  background: $dialog-header-background;
  border-bottom: $dialog-header-border;

  @include mobileExperience {
    padding: 16px 16px;
  }
}

.modal {
  &.mobile-drawer {
    @include mobileExperience {
      &.open {
        .modal-window {
          transform: translateY(0);
        }
      }
    }

    .modal-window {
      @include mobileExperience {
        position: fixed;
        transition: transform var(--modal-transition-duration) ease-out;
        transform: translateY(100%);

        top: auto;
        left: 0;
        right: 0;
        bottom: 0;
        margin-bottom: 0;
        border-radius: $modal-border-radius $modal-border-radius 0 0;
        width: 100%;
        max-width: none;
      }
    }
  }

  &.mobile-full-screen {
    .modal-window {
      max-height: none;

      @include mobileExperience {
        margin: 0;
        width: 100%;
        height: 100%;
        z-index: 95;
        border-radius: 0;
        left: 0;
        top: 0;

        .modal-body {
          padding-top: 0;
        }
      }

      .modal-header.has-title {
        @include mobileExperience {
          padding-left: 16px;
          padding-right: 16px;
        }
      }
    }
  }
}

@include mobileExperience {
  .mobile-hidden {
    display: none;
  }

  .mobile-modals-hidden {
    overflow: auto;

    .modal-backdrop {
      display: none;
    }
  }
}

@include desktopExperience {
  .desktop-hidden {
    display: none;
  }

  .desktop-modals-hidden {
    overflow: auto;

    .modal-backdrop {
      display: none;
    }
  }
}

.modal-window {
  @include desktopExperience {
    transition: opacity var(--modal-transition-duration) ease-out;
    opacity: 0;
  }

  // Login dialog
  .login & {
    width: 420px;

    @include mobileExperience {
      width: auto;
    }

    .join-link {
      margin-top: 0;
      margin-bottom: 16px;
      padding-bottom: 16px;
      border-bottom: 1px solid $light-gray;

      > span {
        margin-right: 6px;
      }

      a {
        display: inline-block;
      }
    }

    .forgot-password-link {
      margin-top: 0;
      margin-bottom: 0;
    }

    .actions {
      gap: 0;

      @include mediumAndLarge {
        display: block;
      }

      button.primary {
        margin-top: 24px;
        flex-basis: auto;
        margin-bottom: 24px;
      }
    }
  }

  // Membership detail modal
  .membership-modal & {
    .membership-modal-inner {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }

    .content-wrapper {
      flex-grow: 1;
    }

    .content .item {
      padding-bottom: 20px;
    }

    .actions {
      display: flex;
      justify-content: flex-end;
      margin-top: 32px;
      padding-top: 32px;
      gap: 16px;
      border-top: 1px solid $light-gray;

      @include mobileExperience {
        margin-top: 24px;
        padding-top: 24px;
      }

      button {
        @include mobileExperience {
          width: 100%;
        }
      }
    }
  }

  // Login or guest dialog
  .login-or-guest-modal & {
    .guest {
      button.primary {
        margin-top: 0;
      }
    }
  }

  .terms-conditions & {
    // A gradient block to cover a small bottom part of the terms content
    .terms-and-conditions-content-wrapper::before {
      $gradient-block-height: 20px;

      @include mobileExperience {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: $gradient-block-height;

        // Workaround for iOS 15.x because using `linear-gradient` with `background` property doesn't work well.
        // The gradient seems to go from transparency to semi-transparent black and then `--dialog-background-color`
        background: $dialog-background-color;
        mask-image: linear-gradient(transparent, #000000 90%);
      }
    }
  }
}

.modal {
  color: inherit;

  &.login {
    @include mobileExperience {
      max-width: none;
    }

    .join-link {
      border-bottom: $divider;
    }

    .actions {
      flex-wrap: wrap;
    }
  }

  &.membership-modal {
    padding: 0;

    h2 {
      margin-top: 0;
    }

    .content {
      .label {
        @extend %label;
      }
    }

    .membership-modal-inner {
      width: 100%;

      > p {
        margin: 0;
      }
    }

    .member-name:last-of-type {
      padding: 0;
    }

    .actions {
      border-top: $divider;
    }

    @include large {
      .modal-body {
        display: flex;

        .content {
          display: flex;
          flex-grow: 1;
          flex-wrap: wrap;

          > * {
            display: flex;
            flex-grow: 1;
            width: 100%;

            .item {
              display: flex;
              flex-direction: column;
              width: 50%;
            }
          }
        }

        &:before {
          background-size: cover;
          background-position: center;
          width: 45%;
        }
      }
    }

    .actions {
      @extend %actions;
      flex-direction: row;

      @include mobileExperience {
        flex-direction: column;
      }
    }
  }

  &.login-or-guest-modal {
    .login-or-guest-dialog > *:first-child {
      @include large {
        border-right: $divider;
      }

      @include smallAndMedium {
        border-bottom: $divider;
      }
    }

    button.primary {
      margin-top: $spacing-s;
    }
  }

  &.confirm-cancel-modal {
    .modal-window {
      text-align: center;

      @include desktopExperience {
        max-width: 30em;
      }
    }

    .message-subtext {
      margin: 0;
    }

    .actions {
      justify-content: center;
      margin-top: 32px;

      @include desktopExperience {
        flex-direction: row-reverse;
        button {
          margin: 0 12px;
        }
      }
    }
  }
}

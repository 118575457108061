// TODO: Merge into styles/links-and-buttons.scss

@mixin iconButton {
  position: fixed;
  border-radius: 100%;
  border: 0;
  width: 40px;
  height: 40px;
  background: $back-button-background-color;
  box-shadow: $back-button-shadow;
  top: 16px;
  left: 16px;
  justify-content: center;
  align-items: center;
  z-index: 1;
  display: none;

  @include mobileExperience {
    display: flex;
  }

  .svg-icon {
    stroke: $back-button-icon-color;
    height: 20px;
  }
}

.back-button {
  @include iconButton;
}

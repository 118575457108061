.edit-order {
  button.cancel-event,
  button.reschedule-event {
    color: $link-color;

    &:hover,
    &:focus {
      color: $link-hover-color;
    }
  }

  .session {
    border: $edit-order-session-border;

    .edit-actions,
    .uneditable-event,
    .toggle-btn,
    .ticket {
      border-top: $edit-order-session-border;
    }
  }

  .ticket {
    background-color: $edit-order-ticket-background-color;
  }

  .toggle-btn {
    color: $body-color;

    svg {
      stroke: currentColor;
    }
  }

  svg.trash-icon {
    stroke: $secondary-color;
  }
}

.reschedule-experience {
  .select-date > h3 {
    @extend %label;
    margin-bottom: 12px;
  }

  .select-session-wrapper {
    margin-bottom: 16px;
  }

  .sticky-footer {
    @include smallAndMedium {
      padding-top: 16px;
    }
  }
}

.cancel-free-order {
  .session-table,
  .session-table .ticket-row,
  .table-head {
    border-color: $cancel-free-tickets-border-color;
  }

  .session-table .ticket-row {
    background-color: $cancel-free-tickets-background-color;
  }
}

// This file must only include mixins, placeholders or variables as executable styles will be duplicated
// because of prependData in vue.config.js.

@import './thresholds';

@mixin large {
  @media screen and (min-width: $large-threshold-min) {
    @content;
  }
}

@mixin small {
  @media screen and (max-width: $small-threshold) {
    @content;
  }
}

@mixin mediumAndLarge {
  @media screen and (min-width: $small-threshold-min) {
    @content;
  }
}

@mixin print {
  @media print {
    @content;
  }
}

@mixin mediumOnly {
  @media screen and (min-width: $small-threshold-min) and (max-width: $large-threshold) {
    @content;
  }
}

@mixin smallAndMedium {
  @media screen and (max-width: $large-threshold) {
    @content;
  }
}

// TODO Refine the breakpoint for the landscape case
@mixin mobileExperience {
  @include small {
    @content;
  }

  @include mediumOnly {
    @media (orientation: landscape) {
      @content;
    }
  }
}

@mixin desktopExperience {
  @include large {
    @content;
  }

  @include mediumOnly {
    @media (orientation: portrait) {
      @content;
    }
  }
}

// TODO Rename/merge/deduplicate responsive() and responsiveProperty().
@mixin responsive($name, $small, $large) {
  #{$name}: $small;
  @include large {
    #{$name}: $large;
  }
}

@mixin responsiveProperty($name, $small, $medium, $large) {
  #{$name}: $small;
  @include mediumOnly {
    #{$name}: $medium;
  }
  @include large {
    #{$name}: $large;
  }
}

@mixin responsiveImage($name, $small, $medium, $large) {
  #{$name}: $small;

  @include mediumOnly {
    #{$name}: $medium;
  }

  @include large {
    #{$name}: $large;
  }
}

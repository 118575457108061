.event-listing {
  // Grid of cards on tablet.
  @include mediumOnly {
    vertical-align: top;
    display: grid;
    column-gap: $spacing-l;
    grid-template-columns: 1fr 1fr;
  }

  article.event {
    text-decoration: none;
    margin-bottom: $spacing-m;
    padding-bottom: $spacing-m;
    grid-auto-rows: min-content;

    @include mediumAndLarge {
      margin-bottom: $spacing-l;
      padding-bottom: $spacing-l;
    }

    &.clickable {
      cursor: pointer;
    }

    > :first-child h2 {
      margin-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
    }

    a.title-link {
      color: $heading-color;
    }

    div.button.primary {
      @extend %primary-button;
      margin-top: $spacing-s;
    }

    @include small {
      &:last-child {
        border-bottom: 0 none;
        margin-bottom: 0;
      }
    }

    // Horizontal layout on desktop.
    @include large {
      &:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
        border-bottom: 0 none;
      }

      figure {
        margin-right: $spacing-m;

        img {
          width: var(--teaser-image-width);
          height: var(--teaser-image-height);
          object-fit: cover;

          &.no-cropping {
            object-fit: contain;
          }
        }
      }

      a.title-link h2 {
        margin-top: 0;
      }
    }
  }
}

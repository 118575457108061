.site-footer-wrapper {
  background-color: $footer-background-color;
  padding: 20px 0;
  margin-top: 32px;

  @include mobileExperience {
    text-align: center;
  }

  &,
  p {
    font-size: 14px;
    color: $footer-text-color;
  }

  p {
    margin: 0;

    @include mobileExperience {
      text-align: center;
    }
  }

  a {
    color: $footer-links-color;

    &:hover,
    &:focus {
      color: $footer-link-color-hover;
    }
  }
}

.content-detail-page {
  .ticket-group-description-wrapper {
    margin-bottom: $spacing-s;

    > h2 {
      @extend %h3;
      margin-bottom: 12px;
    }

    p {
      margin: 0;
    }
  }

  .ticket-type-description {
    .title {
      @extend %h4;
      margin-bottom: 0;
    }

    .subtitle {
      margin-top: $spacing-xs;
    }
  }

  .mobile-content-wrapper {
    .description-link {
      .svg-icon {
        stroke: $link-color;
      }
    }
  }

  .selection,
  .members-only {
    margin-top: $spacing-m;
    padding-top: $spacing-m;

    @include small {
      margin-top: $spacing-m;
      padding-top: $spacing-m;
    }
  }

  .members-only {
    .signup-or-login {
      a.button.login {
        margin-bottom: 16px;
      }
    }
  }

  figure {
    margin-bottom: $spacing-l;
  }

  .selection-wrapper,
  .mobile-content-wrapper {
    padding-left: 0;
    padding-right: 0;

    @include mobileExperience {
      padding-left: $spacing-s;
      padding-right: $spacing-s;
    }
  }

  .radio-group.ticket-types {
    .ticket-type {
      margin-bottom: $spacing-s;

      .custom-value {
        .label,
        input {
          display: block;
        }
      }

      .currency-symbol {
        line-height: 48px;
        padding-right: 4px;
      }
    }
  }

  .price-container {
    @include mobileExperience {
      margin-top: 8px;
    }

    .price-wrapper {
      display: inline-block;

      &.has-discount {
        text-decoration: line-through;
        font-size: 14px;
      }

      &.discount {
        margin-left: 8px;
        color: $primary-color;
      }
    }

    .fees {
      font-size: 12px;
      line-height: 16px;
    }
  }

  .reserve-date-first-form,
  .reserve-quantity-first-form,
  .reserve-single-event-form {
    button.submit.submitting {
      @extend %loading-spinner;

      &:before,
      &:after {
        display: inline-block;
        width: 15px;
        height: 15px;
        vertical-align: middle;
        margin-left: 12px;
        float: right;
      }

      &:after {
        position: absolute;
      }
    }
  }
}

.event-listing article.event .venue,
article.event .venue {
  font-weight: bold;
}
